//libraries
import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";

const Welcome: React.FC = () => (
  <>
    <div className="bg-white py-16 md:py-24 px-8 mx-auto lg:hidden">
      <div className="bg-welcome bg-no-repeat bg-cover p-8 max-w-3xl mx-auto">
        <div className="max-w-lg mx-auto bg-blue-600 text-center p-4 sm:p-12 z-10">
          <h3 className="text-white text-2xl sm:text-3xl tracking-wider m-0 p-0 mb-2 font-bold">
            Welcome
          </h3>
          <div className="uppercase sm:text-sm text-green mb-2 font-thin">
            to the Seychelles Tennis Association
          </div>
          <p className="text-white mt-4 mb-2 float-right">
            Founded in 1955, The objective of the Seychelles Tennis Association
            is to spearhead the development of tennis in Seychelles.
          </p>
          <StaticImage src="../../images/signature.png" alt="signature" />
        </div>
      </div>
    </div>
    <div className="hidden lg:flex max-w-6xl mx-auto py-16 sm:py-24 px-4 sm:px-6 md:px-8">
      <StaticImage src="../../images/welcome.jpg" alt="welcome" />
      <div className="max-w-lg bg-blue-600 text-center p-4 sm:p-8 lg:p-16 md:-ml-80 lg:-ml-52 my-8 z-10">
        <h3 className="text-white text-4xl mb-4 font-bold tracking-wider">
          Welcome
        </h3>
        <h6 className="uppercase text-green mb-4 font-thin tracking-wider">
          to the Seychelles Tennis Association
        </h6>
        <p className="text-white mt-8 mb-2 float-right">
          Founded in 1955, The objective of the Seychelles Tennis Association is
          to spearhead the development of tennis in Seychelles.
        </p>
        <StaticImage src="../../images/signature.png" alt="signature" />
      </div>
    </div>
  </>
);
export default Welcome;
