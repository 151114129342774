//libraries
import React, { Fragment } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Transition, Dialog } from "@headlessui/react";
//containers
import Container from "../../containers/container";

const getLogo = (name: string) => {
  switch (name) {
    case "vijay":
      return (
        <StaticImage
          src="../../images/sponsors/vijay.jpg"
          alt="vijay"
          width={200}
        />
      );
    case "samsung":
      return (
        <StaticImage
          src="../../images/sponsors/samsung.jpg"
          alt="samsung"
          width={200}
        />
      );
    case "penlac":
      return (
        <StaticImage
          src="../../images/sponsors/penlac.jpg"
          alt="penlac"
          width={200}
        />
      );
    case "excel-motors":
      return (
        <StaticImage
          src="../../images/sponsors/excel-motors.jpg"
          alt="Excel Motors"
          width={200}
        />
      );
    case "photo-eden":
      return (
        <StaticImage
          src="../../images/sponsors/photo-eden.jpg"
          alt="Photo Eden"
          width={200}
        />
      );
    case "land-marine":
      return (
        <StaticImage
          src="../../images/sponsors/land-marine.jpg"
          alt="Land Marine"
          width={200}
        />
      );

    default:
      return <p>Name unknown</p>;
  }
};

const sponsors = [
  {
    name: "vijay",
  },
  {
    name: "excel-motors",
  },
  {
    name: "penlac",
  },
  {
    name: "samsung",
  },
  {
    name: "photo-eden",
  },
  {
    name: "land-marine",
  },
];

const otherSponsors = [
  "GLOBAL SUPPLY CENTRE PTY LTD",
  "MOHAN SHOPPING CENTRE PTY LTD",
  "ARASU & COMPANY PTY",
  "SHIPYARD ELECTRICAL AUTOMOTIVE Co.",
  "MARZ ENGINEERING SOLUTION PTY LTD",
  "COCKTAIL PTY LTD",
  "HIBISCUS CAR HIRE PTY LTD",
  "DAMOO AND COMPANY PTY LTD",
  "TIRES X 4 ALL IMPORTS LIMITED",
  "FEDERAL TRADING PTY LTD",
  "HASCAN1. LTD",
  "MIE DE PAIN COMPANY LIMITED",
  "CELLULAR SERVICES",
  "RAMANI CHARITABLE FOUNDATION",
  "AUBERGE CHEZ PLUME",
  "MR CLIVE DELORIE",
  "MRS. MARIAM KANTE",
  "MRS. MYRIAM TIRANT",
  "MS. PEGGY CHANG-TAVE",
  "MS. SARAH RENE",
  "ABACUS SEYCHELLES OFFSHORE COMPANY",
  "MR. CORNELIUS OPPERMAN",
  "MR. SELWYN KNOWLES",
  "MR. DAN FU",
  "MS. FATIME KANTE",
];

const Sponsors: React.FC = () => {
  const [open, setOpen] = React.useState(false);
  return (
    <Container>
      <div className="py-12 sm:py-24">
        <h3 className="text-blue-500 text-center text-4xl md:text-5xl mb-2 sm:mb-4 font-semibold tracking-wider">
          Our Sponsors
        </h3>
        <h6 className="text-green text-xs text-center font-thin mb-10 sm:mb-12 tracking-widest">
          SPONSORS AND PARTNERS
        </h6>
        <div className="grid grid-cols-2 md:grid-cols-3 gap-4 bg-transparent">
          {sponsors.map((sponsor) => (
            <div key={sponsor.name} className="text-center">
              {getLogo(sponsor.name)}
            </div>
          ))}
        </div>
        <div className="text-center">
          <button
            className="text-green font-semibold hover:text-gray-800 mt-8"
            onClick={() => setOpen(true)}
          >
            Additional Sponsors
          </button>
        </div>
        <Transition.Root show={open} as={Fragment}>
          <Dialog
            as="div"
            className="fixed z-50 inset-0 overflow-y-auto px-4 md:px-8"
            onClose={setOpen}
          >
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              <span
                className="hidden sm:inline-block sm:align-middle sm:h-screen"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-5xl sm:w-full sm:p-6">
                  <div>
                    <div className="py-8">
                      <h6 className="text-blue-500 text-sm sm:text-2xl sm:text-center font-semibold mb-10 sm:mb-12 tracking-widest">
                        Additional Sponsors
                      </h6>
                      <div
                        role="list"
                        className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-x-4"
                      >
                        {otherSponsors.map((sponsor) => (
                          <div key={sponsor} className="py-2 text-xs">
                            {sponsor}
                          </div>
                        ))}
                      </div>
                      <div className="sm:text-center mt-4">
                        <button
                          type="button"
                          className="inline-flex justify-center w-1/3 rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-500 text-base font-medium text-white hover:bg-green-600 focus:outline-none sm:text-sm"
                          onClick={() => setOpen(false)}
                        >
                          OK
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
      </div>
    </Container>
  );
};
export default Sponsors;
