//libraries
import * as React from "react";
//containers
import Layout from "../containers/layout";
//components
import Seo from "../components/seo";
import Carousel from "../components/index/carousel";
import Welcome from "../components/index/welcome";
import Sponsors from "../components/index/sponsors";

const IndexPage: React.FC = () => {
  return (
    <Layout>
      <Seo
        title="Home"
        description="visit coaches and courses of seychelles tennis association"
      />
      <Carousel />
      <Welcome />
      <Sponsors />
    </Layout>
  );
};

export default IndexPage;
