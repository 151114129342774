//libraries
import * as React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { StaticImage } from "gatsby-plugin-image";

const Hero: React.FC = () => (
  <Carousel
    autoPlay={true}
    infiniteLoop={true}
    showThumbs={false}
    showStatus={false}
    showArrows={false}
    interval={5000}
  >
    <div>
      <StaticImage src="../../images/sliders/slide1.jpg" alt="slide1" />
    </div>
    <div>
      <StaticImage src="../../images/sliders/slide2.jpg" alt="slide2" />
    </div>
    <div>
      <StaticImage src="../../images/sliders/slide3.jpg" alt="slide3" />
    </div>
  </Carousel>
);
export default Hero;
